<template>
    <div>
        <p style="text-align: center; font-size: 14px;">非正常访问</p>
        <div @click="handleClearLocalStorage" style="position: fixed; left: 50%; bottom: 40px; transform: translateX(-50%); color: #fd3e3e;">
            点击清空缓存数据
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        /** 清空缓存数据 */
        handleClearLocalStorage() {
            window.localStorage.clear();
            alert("缓存已清空，请关闭后重新打开");
        }
    },
}
</script>

<style>

</style>